import ImageButtonComponent from '@wix/thunderbolt-elements/src/components/ImageButton/viewer/ImageButton';


const ImageButton = {
  component: ImageButtonComponent
};


export const components = {
  ['ImageButton']: ImageButton
};

